module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FinDiETou","short_name":"FinDiETou","start_url":"/","background_color":"#FFF","theme_color":"#eb5e35","description":"FinDiETou Formation","lang":"fr","display":"standalone","orientation":"landscape","icon":"./static/icon-path-logo.png","cache_busting_mode":"none","theme_color_in_head":true,"crossOrigin":"use-credentials","legacy":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/404","/di/maintenance","/di/end","/fr/end","/di/home","/fr/home","/di","/fr","/di/part-1","/fr/part-1","/di/part-1-astuces","/fr/part-1-astuces","/di/part-1-citation","/fr/part-1-citation","/di/part-1-conseil","/fr/part-1-conseil","/di/part-1-quizz","/fr/part-1-quizz","/di/part-1-quizz-form","/fr/part-1-quizz-form","/di/part-1-evaluation","/fr/part-1-evaluation","/di/part-1-videos","/fr/part-1-videos","/di/part-1-success","/fr/part-1-success","/di/part-2","/fr/part-2","/di/part-2-astuces","/fr/part-2-astuces","/di/part-2-citation","/fr/part-2-citation","/di/part-2-conseil","/fr/part-2-conseil","/di/part-2-quizz","/fr/part-2-quizz","/di/part-2-quizz-form","/fr/part-2-quizz-form","/di/part-2-evaluation","/fr/part-2-evaluation","/di/part-2-videos","/fr/part-2-videos","/di/part-2-success","/fr/part-2-success","/di/part-3","/fr/part-3","/di/part-3-astuces","/fr/part-3-astuces","/di/part-3-citation","/fr/part-3-citation","/di/part-3-conseil","/fr/part-3-conseil","/di/part-3-quizz","/fr/part-3-quizz","/di/part-3-quizz-form","/fr/part-3-quizz-form","/di/part-3-evaluation","/fr/part-3-evaluation","/di/part-3-videos","/fr/part-3-videos","/di/part-3-success","/fr/part-3-success","/di/part-4","/fr/part-4","/di/part-4-quizz-form","/fr/part-4-quizz-form","/di/part-4-success","/fr/part-4-success"],"workboxConfig":{"globPatterns":["**/*.{js,jpg,png,html,pdf,css,mp3}"],"runtimeCaching":[{"urlPattern":{},"handler":"NetworkOnly"},{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"CacheFirst"}]},"appendScript":"/root/project/application/src/sw-workbox-custom.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-PCR9EX1NM9"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
