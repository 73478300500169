/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/**
 * Global SCSS styles
 */

import './src/styles/global.scss';

/**
 * Typefaces
 */

require('typeface-roboto');

import '@fontsource/roboto-slab';

/**
 * Matomo tag on install on phone and hide install button when installed
 */

import trackEvent from './src/components/analytics';

if (typeof window !== 'undefined') {
  window.addEventListener('appinstalled', (evt) => {
    trackEvent('Install PWA', 'App is installed on phone', 'yes');
    // console.log('a2hs', 'installed');
    document.getElementById('installPwa').remove();
  });

  window.addEventListener('beforeinstallprompt', (e) => {
    let deferredPrompt;
    const addBtn = document.getElementById('add-button');
    // addBtn.style.display = 'none';
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI to notify the user they can add to home screen
    // addBtn.style.display = 'block';

    addBtn.addEventListener('click', (e) => {
      // hide our user interface that shows our A2HS button
      // addBtn.style.display = 'none';
      // Show the prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        // if (choiceResult.outcome === 'accepted') {
        //   console.log('User accepted the A2HS prompt');
        // } else {
        //   console.log('User dismissed the A2HS prompt');
        // }
        deferredPrompt = null;
      });
    });
  });
}

/**
 * Loader
 */

// export const onClientEntry = () => {
//   document.getElementById('loader-wrapper').style.display = 'flex';
// };

// export const onPreRouteUpdate = () => {
//   document.getElementById('loader-wrapper').style.display = 'flex';
// };

// export const onRouteUpdateDelayed = () => {
//   document.getElementById('loader-wrapper').style.display = 'flex';
// };

// export const onRouteUpdate = () => {
//   setTimeout(() => {
//     document.getElementById('loader-wrapper').style.display = 'none';
//   }, 0);
// };

/**
 * Automatically reload page if service worker updates
 */

// export const onServiceWorkerUpdateReady = () => {
//   if (typeof window !== 'undefined') {
//     window.location.reload(true);
//   }
// };

/**
 * Load external scripts
 */

// const addScript = url => {
//   const script = document.createElement("script")
//   script.src = url
//   script.async = true
//   document.body.appendChild(script)
// }
//
// export const onClientEntry = () => {
//   window.onload = () => {
//     addScript("https://fast.wistia.com/embed/medias/9rvl8vgrzg.jsonp")
//     addScript("https://fast.wistia.com/assets/external/E-v1.js")
//   }
// }

/**
 * Logs when the client route changes
 */

// exports.onRouteUpdate = ({ location, prevLocation }) => {
//   console.log("new pathname", location.pathname)
//   console.log("old pathname", prevLocation ? prevLocation.pathname : null)
// }

/**
 * Wraps every page in a component
 */

// exports.wrapPageElement = ({ element, props }) => {
//   return <Layout {...props}>{element}</Layout>
// }
