const trackEvent = (category, action, label, value) => {
  if (window._paq) {
    window._paq.push([
      'trackEvent',
      category.toString(),
      action.toString(),
      label ? label.toString() + (value ? '::' + value : '') : undefined,
    ]);
  }
};

export default trackEvent;
