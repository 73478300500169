// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-end-ba-js": () => import("./../../../src/pages/end.ba.js" /* webpackChunkName: "component---src-pages-end-ba-js" */),
  "component---src-pages-end-di-js": () => import("./../../../src/pages/end.di.js" /* webpackChunkName: "component---src-pages-end-di-js" */),
  "component---src-pages-end-fr-js": () => import("./../../../src/pages/end.fr.js" /* webpackChunkName: "component---src-pages-end-fr-js" */),
  "component---src-pages-home-ba-js": () => import("./../../../src/pages/home.ba.js" /* webpackChunkName: "component---src-pages-home-ba-js" */),
  "component---src-pages-home-di-js": () => import("./../../../src/pages/home.di.js" /* webpackChunkName: "component---src-pages-home-di-js" */),
  "component---src-pages-home-fr-js": () => import("./../../../src/pages/home.fr.js" /* webpackChunkName: "component---src-pages-home-fr-js" */),
  "component---src-pages-index-ba-js": () => import("./../../../src/pages/index.ba.js" /* webpackChunkName: "component---src-pages-index-ba-js" */),
  "component---src-pages-index-di-js": () => import("./../../../src/pages/index.di.js" /* webpackChunkName: "component---src-pages-index-di-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-part-1-astuces-ba-js": () => import("./../../../src/pages/part-1-astuces.ba.js" /* webpackChunkName: "component---src-pages-part-1-astuces-ba-js" */),
  "component---src-pages-part-1-astuces-di-js": () => import("./../../../src/pages/part-1-astuces.di.js" /* webpackChunkName: "component---src-pages-part-1-astuces-di-js" */),
  "component---src-pages-part-1-astuces-fr-js": () => import("./../../../src/pages/part-1-astuces.fr.js" /* webpackChunkName: "component---src-pages-part-1-astuces-fr-js" */),
  "component---src-pages-part-1-ba-js": () => import("./../../../src/pages/part-1.ba.js" /* webpackChunkName: "component---src-pages-part-1-ba-js" */),
  "component---src-pages-part-1-citation-ba-js": () => import("./../../../src/pages/part-1-citation.ba.js" /* webpackChunkName: "component---src-pages-part-1-citation-ba-js" */),
  "component---src-pages-part-1-citation-di-js": () => import("./../../../src/pages/part-1-citation.di.js" /* webpackChunkName: "component---src-pages-part-1-citation-di-js" */),
  "component---src-pages-part-1-citation-fr-js": () => import("./../../../src/pages/part-1-citation.fr.js" /* webpackChunkName: "component---src-pages-part-1-citation-fr-js" */),
  "component---src-pages-part-1-conseil-ba-js": () => import("./../../../src/pages/part-1-conseil.ba.js" /* webpackChunkName: "component---src-pages-part-1-conseil-ba-js" */),
  "component---src-pages-part-1-conseil-di-js": () => import("./../../../src/pages/part-1-conseil.di.js" /* webpackChunkName: "component---src-pages-part-1-conseil-di-js" */),
  "component---src-pages-part-1-conseil-fr-js": () => import("./../../../src/pages/part-1-conseil.fr.js" /* webpackChunkName: "component---src-pages-part-1-conseil-fr-js" */),
  "component---src-pages-part-1-di-js": () => import("./../../../src/pages/part-1.di.js" /* webpackChunkName: "component---src-pages-part-1-di-js" */),
  "component---src-pages-part-1-evaluation-ba-js": () => import("./../../../src/pages/part-1-evaluation.ba.js" /* webpackChunkName: "component---src-pages-part-1-evaluation-ba-js" */),
  "component---src-pages-part-1-evaluation-di-js": () => import("./../../../src/pages/part-1-evaluation.di.js" /* webpackChunkName: "component---src-pages-part-1-evaluation-di-js" */),
  "component---src-pages-part-1-evaluation-fr-js": () => import("./../../../src/pages/part-1-evaluation.fr.js" /* webpackChunkName: "component---src-pages-part-1-evaluation-fr-js" */),
  "component---src-pages-part-1-fr-js": () => import("./../../../src/pages/part-1.fr.js" /* webpackChunkName: "component---src-pages-part-1-fr-js" */),
  "component---src-pages-part-1-quizz-ba-js": () => import("./../../../src/pages/part-1-quizz.ba.js" /* webpackChunkName: "component---src-pages-part-1-quizz-ba-js" */),
  "component---src-pages-part-1-quizz-di-js": () => import("./../../../src/pages/part-1-quizz.di.js" /* webpackChunkName: "component---src-pages-part-1-quizz-di-js" */),
  "component---src-pages-part-1-quizz-form-ba-js": () => import("./../../../src/pages/part-1-quizz-form.ba.js" /* webpackChunkName: "component---src-pages-part-1-quizz-form-ba-js" */),
  "component---src-pages-part-1-quizz-form-di-js": () => import("./../../../src/pages/part-1-quizz-form.di.js" /* webpackChunkName: "component---src-pages-part-1-quizz-form-di-js" */),
  "component---src-pages-part-1-quizz-form-fr-js": () => import("./../../../src/pages/part-1-quizz-form.fr.js" /* webpackChunkName: "component---src-pages-part-1-quizz-form-fr-js" */),
  "component---src-pages-part-1-quizz-fr-js": () => import("./../../../src/pages/part-1-quizz.fr.js" /* webpackChunkName: "component---src-pages-part-1-quizz-fr-js" */),
  "component---src-pages-part-1-success-ba-js": () => import("./../../../src/pages/part-1-success.ba.js" /* webpackChunkName: "component---src-pages-part-1-success-ba-js" */),
  "component---src-pages-part-1-success-di-js": () => import("./../../../src/pages/part-1-success.di.js" /* webpackChunkName: "component---src-pages-part-1-success-di-js" */),
  "component---src-pages-part-1-success-fr-js": () => import("./../../../src/pages/part-1-success.fr.js" /* webpackChunkName: "component---src-pages-part-1-success-fr-js" */),
  "component---src-pages-part-1-videos-ba-js": () => import("./../../../src/pages/part-1-videos.ba.js" /* webpackChunkName: "component---src-pages-part-1-videos-ba-js" */),
  "component---src-pages-part-1-videos-di-js": () => import("./../../../src/pages/part-1-videos.di.js" /* webpackChunkName: "component---src-pages-part-1-videos-di-js" */),
  "component---src-pages-part-1-videos-fr-js": () => import("./../../../src/pages/part-1-videos.fr.js" /* webpackChunkName: "component---src-pages-part-1-videos-fr-js" */),
  "component---src-pages-part-2-astuces-ba-js": () => import("./../../../src/pages/part-2-astuces.ba.js" /* webpackChunkName: "component---src-pages-part-2-astuces-ba-js" */),
  "component---src-pages-part-2-astuces-di-js": () => import("./../../../src/pages/part-2-astuces.di.js" /* webpackChunkName: "component---src-pages-part-2-astuces-di-js" */),
  "component---src-pages-part-2-astuces-fr-js": () => import("./../../../src/pages/part-2-astuces.fr.js" /* webpackChunkName: "component---src-pages-part-2-astuces-fr-js" */),
  "component---src-pages-part-2-ba-js": () => import("./../../../src/pages/part-2.ba.js" /* webpackChunkName: "component---src-pages-part-2-ba-js" */),
  "component---src-pages-part-2-citation-ba-js": () => import("./../../../src/pages/part-2-citation.ba.js" /* webpackChunkName: "component---src-pages-part-2-citation-ba-js" */),
  "component---src-pages-part-2-citation-di-js": () => import("./../../../src/pages/part-2-citation.di.js" /* webpackChunkName: "component---src-pages-part-2-citation-di-js" */),
  "component---src-pages-part-2-citation-fr-js": () => import("./../../../src/pages/part-2-citation.fr.js" /* webpackChunkName: "component---src-pages-part-2-citation-fr-js" */),
  "component---src-pages-part-2-conseil-ba-js": () => import("./../../../src/pages/part-2-conseil.ba.js" /* webpackChunkName: "component---src-pages-part-2-conseil-ba-js" */),
  "component---src-pages-part-2-conseil-di-js": () => import("./../../../src/pages/part-2-conseil.di.js" /* webpackChunkName: "component---src-pages-part-2-conseil-di-js" */),
  "component---src-pages-part-2-conseil-fr-js": () => import("./../../../src/pages/part-2-conseil.fr.js" /* webpackChunkName: "component---src-pages-part-2-conseil-fr-js" */),
  "component---src-pages-part-2-di-js": () => import("./../../../src/pages/part-2.di.js" /* webpackChunkName: "component---src-pages-part-2-di-js" */),
  "component---src-pages-part-2-evaluation-ba-js": () => import("./../../../src/pages/part-2-evaluation.ba.js" /* webpackChunkName: "component---src-pages-part-2-evaluation-ba-js" */),
  "component---src-pages-part-2-evaluation-di-js": () => import("./../../../src/pages/part-2-evaluation.di.js" /* webpackChunkName: "component---src-pages-part-2-evaluation-di-js" */),
  "component---src-pages-part-2-evaluation-fr-js": () => import("./../../../src/pages/part-2-evaluation.fr.js" /* webpackChunkName: "component---src-pages-part-2-evaluation-fr-js" */),
  "component---src-pages-part-2-fr-js": () => import("./../../../src/pages/part-2.fr.js" /* webpackChunkName: "component---src-pages-part-2-fr-js" */),
  "component---src-pages-part-2-quizz-ba-js": () => import("./../../../src/pages/part-2-quizz.ba.js" /* webpackChunkName: "component---src-pages-part-2-quizz-ba-js" */),
  "component---src-pages-part-2-quizz-di-js": () => import("./../../../src/pages/part-2-quizz.di.js" /* webpackChunkName: "component---src-pages-part-2-quizz-di-js" */),
  "component---src-pages-part-2-quizz-form-ba-js": () => import("./../../../src/pages/part-2-quizz-form.ba.js" /* webpackChunkName: "component---src-pages-part-2-quizz-form-ba-js" */),
  "component---src-pages-part-2-quizz-form-di-js": () => import("./../../../src/pages/part-2-quizz-form.di.js" /* webpackChunkName: "component---src-pages-part-2-quizz-form-di-js" */),
  "component---src-pages-part-2-quizz-form-fr-js": () => import("./../../../src/pages/part-2-quizz-form.fr.js" /* webpackChunkName: "component---src-pages-part-2-quizz-form-fr-js" */),
  "component---src-pages-part-2-quizz-fr-js": () => import("./../../../src/pages/part-2-quizz.fr.js" /* webpackChunkName: "component---src-pages-part-2-quizz-fr-js" */),
  "component---src-pages-part-2-success-ba-js": () => import("./../../../src/pages/part-2-success.ba.js" /* webpackChunkName: "component---src-pages-part-2-success-ba-js" */),
  "component---src-pages-part-2-success-di-js": () => import("./../../../src/pages/part-2-success.di.js" /* webpackChunkName: "component---src-pages-part-2-success-di-js" */),
  "component---src-pages-part-2-success-fr-js": () => import("./../../../src/pages/part-2-success.fr.js" /* webpackChunkName: "component---src-pages-part-2-success-fr-js" */),
  "component---src-pages-part-2-videos-ba-js": () => import("./../../../src/pages/part-2-videos.ba.js" /* webpackChunkName: "component---src-pages-part-2-videos-ba-js" */),
  "component---src-pages-part-2-videos-di-js": () => import("./../../../src/pages/part-2-videos.di.js" /* webpackChunkName: "component---src-pages-part-2-videos-di-js" */),
  "component---src-pages-part-2-videos-fr-js": () => import("./../../../src/pages/part-2-videos.fr.js" /* webpackChunkName: "component---src-pages-part-2-videos-fr-js" */),
  "component---src-pages-part-3-astuces-ba-js": () => import("./../../../src/pages/part-3-astuces.ba.js" /* webpackChunkName: "component---src-pages-part-3-astuces-ba-js" */),
  "component---src-pages-part-3-astuces-di-js": () => import("./../../../src/pages/part-3-astuces.di.js" /* webpackChunkName: "component---src-pages-part-3-astuces-di-js" */),
  "component---src-pages-part-3-astuces-fr-js": () => import("./../../../src/pages/part-3-astuces.fr.js" /* webpackChunkName: "component---src-pages-part-3-astuces-fr-js" */),
  "component---src-pages-part-3-ba-js": () => import("./../../../src/pages/part-3.ba.js" /* webpackChunkName: "component---src-pages-part-3-ba-js" */),
  "component---src-pages-part-3-citation-ba-js": () => import("./../../../src/pages/part-3-citation.ba.js" /* webpackChunkName: "component---src-pages-part-3-citation-ba-js" */),
  "component---src-pages-part-3-citation-di-js": () => import("./../../../src/pages/part-3-citation.di.js" /* webpackChunkName: "component---src-pages-part-3-citation-di-js" */),
  "component---src-pages-part-3-citation-fr-js": () => import("./../../../src/pages/part-3-citation.fr.js" /* webpackChunkName: "component---src-pages-part-3-citation-fr-js" */),
  "component---src-pages-part-3-conseil-ba-js": () => import("./../../../src/pages/part-3-conseil.ba.js" /* webpackChunkName: "component---src-pages-part-3-conseil-ba-js" */),
  "component---src-pages-part-3-conseil-di-js": () => import("./../../../src/pages/part-3-conseil.di.js" /* webpackChunkName: "component---src-pages-part-3-conseil-di-js" */),
  "component---src-pages-part-3-conseil-fr-js": () => import("./../../../src/pages/part-3-conseil.fr.js" /* webpackChunkName: "component---src-pages-part-3-conseil-fr-js" */),
  "component---src-pages-part-3-di-js": () => import("./../../../src/pages/part-3.di.js" /* webpackChunkName: "component---src-pages-part-3-di-js" */),
  "component---src-pages-part-3-evaluation-ba-js": () => import("./../../../src/pages/part-3-evaluation.ba.js" /* webpackChunkName: "component---src-pages-part-3-evaluation-ba-js" */),
  "component---src-pages-part-3-evaluation-di-js": () => import("./../../../src/pages/part-3-evaluation.di.js" /* webpackChunkName: "component---src-pages-part-3-evaluation-di-js" */),
  "component---src-pages-part-3-evaluation-fr-js": () => import("./../../../src/pages/part-3-evaluation.fr.js" /* webpackChunkName: "component---src-pages-part-3-evaluation-fr-js" */),
  "component---src-pages-part-3-fr-js": () => import("./../../../src/pages/part-3.fr.js" /* webpackChunkName: "component---src-pages-part-3-fr-js" */),
  "component---src-pages-part-3-quizz-ba-js": () => import("./../../../src/pages/part-3-quizz.ba.js" /* webpackChunkName: "component---src-pages-part-3-quizz-ba-js" */),
  "component---src-pages-part-3-quizz-di-js": () => import("./../../../src/pages/part-3-quizz.di.js" /* webpackChunkName: "component---src-pages-part-3-quizz-di-js" */),
  "component---src-pages-part-3-quizz-form-ba-js": () => import("./../../../src/pages/part-3-quizz-form.ba.js" /* webpackChunkName: "component---src-pages-part-3-quizz-form-ba-js" */),
  "component---src-pages-part-3-quizz-form-di-js": () => import("./../../../src/pages/part-3-quizz-form.di.js" /* webpackChunkName: "component---src-pages-part-3-quizz-form-di-js" */),
  "component---src-pages-part-3-quizz-form-fr-js": () => import("./../../../src/pages/part-3-quizz-form.fr.js" /* webpackChunkName: "component---src-pages-part-3-quizz-form-fr-js" */),
  "component---src-pages-part-3-quizz-fr-js": () => import("./../../../src/pages/part-3-quizz.fr.js" /* webpackChunkName: "component---src-pages-part-3-quizz-fr-js" */),
  "component---src-pages-part-3-success-ba-js": () => import("./../../../src/pages/part-3-success.ba.js" /* webpackChunkName: "component---src-pages-part-3-success-ba-js" */),
  "component---src-pages-part-3-success-di-js": () => import("./../../../src/pages/part-3-success.di.js" /* webpackChunkName: "component---src-pages-part-3-success-di-js" */),
  "component---src-pages-part-3-success-fr-js": () => import("./../../../src/pages/part-3-success.fr.js" /* webpackChunkName: "component---src-pages-part-3-success-fr-js" */),
  "component---src-pages-part-3-videos-ba-js": () => import("./../../../src/pages/part-3-videos.ba.js" /* webpackChunkName: "component---src-pages-part-3-videos-ba-js" */),
  "component---src-pages-part-3-videos-di-js": () => import("./../../../src/pages/part-3-videos.di.js" /* webpackChunkName: "component---src-pages-part-3-videos-di-js" */),
  "component---src-pages-part-3-videos-fr-js": () => import("./../../../src/pages/part-3-videos.fr.js" /* webpackChunkName: "component---src-pages-part-3-videos-fr-js" */),
  "component---src-pages-part-4-ba-js": () => import("./../../../src/pages/part-4.ba.js" /* webpackChunkName: "component---src-pages-part-4-ba-js" */),
  "component---src-pages-part-4-di-js": () => import("./../../../src/pages/part-4.di.js" /* webpackChunkName: "component---src-pages-part-4-di-js" */),
  "component---src-pages-part-4-fr-js": () => import("./../../../src/pages/part-4.fr.js" /* webpackChunkName: "component---src-pages-part-4-fr-js" */),
  "component---src-pages-part-4-quizz-form-ba-js": () => import("./../../../src/pages/part-4-quizz-form.ba.js" /* webpackChunkName: "component---src-pages-part-4-quizz-form-ba-js" */),
  "component---src-pages-part-4-quizz-form-di-js": () => import("./../../../src/pages/part-4-quizz-form.di.js" /* webpackChunkName: "component---src-pages-part-4-quizz-form-di-js" */),
  "component---src-pages-part-4-quizz-form-fr-js": () => import("./../../../src/pages/part-4-quizz-form.fr.js" /* webpackChunkName: "component---src-pages-part-4-quizz-form-fr-js" */),
  "component---src-pages-part-4-success-ba-js": () => import("./../../../src/pages/part-4-success.ba.js" /* webpackChunkName: "component---src-pages-part-4-success-ba-js" */),
  "component---src-pages-part-4-success-di-js": () => import("./../../../src/pages/part-4-success.di.js" /* webpackChunkName: "component---src-pages-part-4-success-di-js" */),
  "component---src-pages-part-4-success-fr-js": () => import("./../../../src/pages/part-4-success.fr.js" /* webpackChunkName: "component---src-pages-part-4-success-fr-js" */)
}

